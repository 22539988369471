
import {defineComponent, ref} from 'vue';

import {CategorySupply} from '@/core/interfaces/Models';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {useCreatingForm} from '@/core/hooks/use-creating-form';
import {getErrorMsg, swalErrNotification, swalNotification} from '@/core/helpers/utils';
import {IDanhMucVatTuResponseBase, ITaiKhoanResponseBase} from '@/core/interfaces/ApiResponses';
import {useLoading} from '@/core/hooks/use-loading';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import TaiKhoanSelection from '../../../../components/shared/tai-khoan-selection/TaiKhoanSelection.vue';

interface CategorySupplyWithParent extends CategorySupply {
	parentName: string;
}

export default defineComponent({
	name: "danh-muc-vat-tu-form",

	components: {
		ButtonCustom,
		TaiKhoanSelection,
	},

	setup() {
		const { goBack, push, replace, id } = useRouterCustom();
		const { formRef, isCreating } = useCreatingForm();
		const { setLoading, isLoading, startLoading, endLoading } = useLoading();
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		const taiKhoanData = ref<{
			id: number;
			maTaiKhoan: string;
			maChiTiet?: string;
			tenTaiKhoan: string;
		}>({
			id: 0,
			maChiTiet: '',
			maTaiKhoan: '',
			tenTaiKhoan: '',
		});

		const danhMucVatTuFormValue = ref({
			ten_danh_muc: '',
			ma_danh_muc: '',
			mo_ta: '',
			id_cha: 0,
		});

		return {
			ButtonsType, ButtonTypeColors,
			danhMucVatTuId: id,
			isCreating,
			formRef,
			danhMucVatTuFormValue,
			goBack, push, replace,
			setLoading, isLoading, startLoading, endLoading,
			taiKhoanData,
		}
	},

	data() {
		return {
			categorySupplies: [] as CategorySupplyWithParent[],
			danhMucChaItems: [] as IDanhMucVatTuResponseBase[],
			rules: {
				ten_danh_muc: [
					{
						required: true,
						message: 'Mời bạn nhập tên danh mục vật tư',
						trigger: 'blur',
					},
				],
				ma_danh_muc: [
					{
						required: true,
						message: 'Phải nhập mã danh mục',
						trigger: 'blur',
					},
				],
				mo_ta: [
					{
						required: false,
					},
				],
				id_cha: [
					{
						required: false,
					},
				],
			}
		}
	},

	async mounted() {
		setCurrentPageTitle('Danh mục vật tư');

		try {
			const { data: { data: { data: res } } } = await DanhMucVatTuService.list();
			this.danhMucChaItems = res;

			if (!this.isCreating) {
				this.startLoading();
				const {
					data: {
						data: { ten_danh_muc, ma_danh_muc, mo_ta, id_cha, tk_ketoan },
					},
				} = await DanhMucVatTuService.get(this.danhMucVatTuId);
				this.danhMucVatTuFormValue.ten_danh_muc = ten_danh_muc;
				this.danhMucVatTuFormValue.ma_danh_muc = ma_danh_muc;
				this.danhMucVatTuFormValue.mo_ta = mo_ta;
				this.danhMucVatTuFormValue.id_cha = +id_cha;
				if (tk_ketoan) {
					const { matk, mact, id: id_tai_khoan, tentk } = tk_ketoan;
					this.taiKhoanData.maTaiKhoan = matk;
					this.taiKhoanData.maChiTiet = mact;
					this.taiKhoanData.id = id_tai_khoan;
					this.taiKhoanData.tenTaiKhoan = tentk;
				}
				this.endLoading();
			}
		} catch (error) {
			this.endLoading();
			await swalNotification(
				getErrorMsg(error, 'Xin lỗi, có vẻ có lỗi xảy ra khi lấy danh sách danh mục vật tư có sẵn'),
				'error'
			)
		}
	},

	computed: {
		isMaDanhMucEditable() {
			return (process.env.VUE_APP_EDITABLE_MA_DANH_MUC_TOA_XE === 'false');
		}
	},

	methods: {
		clear() {
			this.danhMucVatTuFormValue.ten_danh_muc = '';
			this.danhMucVatTuFormValue.mo_ta = '';
			this.danhMucVatTuFormValue.id_cha = 0;
			if (this.isCreating || this.isMaDanhMucEditable) {
				this.danhMucVatTuFormValue.ma_danh_muc = '';
			}
		},

		onSelectTaiKhoan({
			taiKhoanItem: {
				id: id_tai_khoan,
				ma_tai_khoan,
				ma_chi_tiet,
				ten_tai_khoan,
			}
		}: {
			taiKhoanItem: ITaiKhoanResponseBase,
		}) {
			this.taiKhoanData.maTaiKhoan = ma_tai_khoan;
			this.taiKhoanData.tenTaiKhoan = ten_tai_khoan;
			this.taiKhoanData.id = id_tai_khoan;

			if (ma_chi_tiet) {
				this.taiKhoanData.maChiTiet = ma_chi_tiet.toString();
			}
		},

		submit() {
			if (!this.formRef) {
				return;
			}

			if (!this.taiKhoanData.maTaiKhoan) {
				swalErrNotification(
					null,
					'Bắt buộc phải chọn mã tài khoản',
				);

				return;
			}

			this.formRef.validate(async (valid) => {
				if (valid) {
					this.setLoading(true);
					this.startLoading();

					try {
						if (this.isCreating) {
							const {
								data: {
									data: { id },
								},
							} = await DanhMucVatTuService.create({
								ten_danh_muc: this.danhMucVatTuFormValue.ten_danh_muc,
								ma_danh_muc: this.danhMucVatTuFormValue.ma_danh_muc,
								mo_ta: this.danhMucVatTuFormValue.mo_ta,
								id_cha: this.danhMucVatTuFormValue.id_cha,
								id_tai_khoan: this.taiKhoanData.id,
								ma_tai_khoan: this.taiKhoanData.maTaiKhoan,
								ma_chi_tiet: this.taiKhoanData.maChiTiet,
								ten_tai_khoan: this.taiKhoanData.tenTaiKhoan,
							});

							this.danhMucVatTuId = id;
						} else {
							const {
								data: {
									data: { id },
								},
							} = await DanhMucVatTuService.update({
								ten_danh_muc: this.danhMucVatTuFormValue.ten_danh_muc,
								ma_danh_muc: this.danhMucVatTuFormValue.ma_danh_muc,
								mo_ta: this.danhMucVatTuFormValue.mo_ta,
								id_cha: this.danhMucVatTuFormValue.id_cha,
								id_tai_khoan: this.taiKhoanData.id,
								ma_tai_khoan: this.taiKhoanData.maTaiKhoan,
								ma_chi_tiet: this.taiKhoanData.maChiTiet,
								ten_tai_khoan: this.taiKhoanData.tenTaiKhoan,
								id: this.danhMucVatTuId,
							});
						}

						this.setLoading(false);
						this.endLoading();
						await swalNotification(
							`Danh mục vật tư được ${this.isCreating ? 'tạo mới' : 'cập nhật'} thành công`
						);

						await this.replace(`/quan-ly/danh-muc-vat-tu/chi-tiet/${this.danhMucVatTuId}`);
					} catch (e) {
						// TODO: update để validate lỗi trùng mã danh mục khgi cập nhật
						this.setLoading(false);
						this.endLoading();
						await swalNotification(
							getErrorMsg(e, 'Xin lỗi, có vẻ có lỗi xảy ra, mời bạn thực hiện lại'),
							'error')
					}
				} else {
					return false;
				}
			});
		}
	},
});
